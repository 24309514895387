//@author: mandy
import { S25Util } from "../../util/s25-util";
import { jSith } from "../../util/jquery-replacement";
import { OptService } from "../../services/opt.service";
import { Timeout } from "../../decorators/timeout.decorator";
import { DropDownItem } from "../../pojo/DropDownItem";
import { OptBean, ViewButtonValue } from "./s25.opt.component";
import { Item } from "../../pojo/Item";
import { Proto } from "../../pojo/Proto";
import { AvailCompType } from "../../services/avail.service";
import { S25Const } from "../../util/s25-const";

declare global {
    interface Window {
        angBridge: any;
    }
}

export interface OptUtilI {
    refreshF: (callback: any) => any;
    comptype?: string;
    compsubject?: Item.Subject;
    origCompsubject?: Item.Subject;
    date?: Date;
    autoInit: boolean;
    helpTopic?: any;
    itemId?: number;
    itemTypeId?: number;
    searchContext?: any;
    optCached?: any;
    isauth?: boolean;
    lastupdate?: Date | string | number;
    messages?: { [key: string]: string };
    datesOption?: {
        value: ViewButtonValue;
    };
    chosen?: {
        obj: DropDownItem;
    };
    separated?: {
        value: Proto.NumericalBoolean;
    };
    editable?: {
        value: boolean;
    };
    utilRateViz?: {
        value: {
            type: number;
        };
    };
    utilVizChoices?: {
        disp: string;
        type: string;
        itemProp: string;
    }[];
    taskType?: any;
    onlyInitOnce?: boolean;
    canRegister?: boolean;
    skipDaysPref?: boolean;
    disableRelatedLocations?: boolean;
    isCalendar?: boolean;
    isSearchCalendar?: boolean;
    datePropertyValue?: boolean | string;
    hasOpt?: boolean;
    hasStickyView?: boolean;
    hasSearchChooser?: boolean;
    hasRelatedEventsCheckbox?: boolean;
    hasRelatedLocationsCheckbox?: boolean;
    hasCombineRelatedEventsCheckbox?: boolean;
    hasAddtlTime?: boolean;
    hasDatepicker?: boolean;
    hasVariableDatepicker?: boolean;
    hasWeekDatepicker?: boolean;
    hasWeeksChooser?: boolean;
    hasDaysChooser?: boolean;
    hasDaysSelector?: boolean;
    hasUtilViz?: boolean;
    hasDateViews?: boolean;
    hasFutureOnly?: boolean;
    hasAllDates?: boolean;
    hasRecentHistory?: boolean;
    hasEventTaskTypeChooser?: boolean;
    hasEventStateChange?: boolean;
    hasColumnChooser?: boolean;
    hasCreateTodoButton?: boolean;
    hasAvailSeparatedChooser?: boolean;
    hasMoreActions?: boolean;
    hasRefresh?: boolean;
    hasTimestamp?: boolean;
    hasOfficeHoursSlider?: boolean;
    hasClose?: boolean;
    hasMode?: boolean;
    hasBBStyle?: boolean;
    hasSnapToGrid?: boolean;
    hasDatePropertyDropdown?: boolean;
    hasBlackoutsCheckbox?: boolean;
    hasAlwaysShareLocation?: boolean;
    hasAvailLegend?: boolean;
    hasIncludeRequested?: boolean;
    hasEditEvent?: boolean;
    hasRegister?: boolean;
    hasPagination?: boolean;
    hasEditToggle?: boolean;
    hasMultiQuery?: boolean;
    hasCancelRequest?: boolean;
    hasSecurity?: boolean;
    hasEditableCheckbox?: boolean;
}

export class OptUtil {
    public static DEFAULTS: OptUtilI = {
        refreshF: null,
        comptype: null,
        compsubject: null,
        origCompsubject: null,
        date: null,
        datesOption: null,
        autoInit: true, //default to true
        helpTopic: null,
        itemId: null,
        itemTypeId: null,
        searchContext: null,
        optCached: null,
        isauth: null,
        messages: { message: "" },
        chosen: null,
        lastupdate: null,
        separated: null,
        editable: null,
        utilRateViz: null,
        taskType: null,
        hasOpt: true, //default to true
        hasStickyView: false,
        hasSearchChooser: false,
        hasRelatedEventsCheckbox: false,
        hasRelatedLocationsCheckbox: false,
        hasCombineRelatedEventsCheckbox: false,
        hasAddtlTime: false,
        hasDatepicker: false,
        hasVariableDatepicker: false,
        hasWeekDatepicker: false,
        hasWeeksChooser: false,
        hasDaysChooser: false,
        hasDaysSelector: false,
        hasUtilViz: false,
        hasDateViews: false,
        hasFutureOnly: false,
        hasAllDates: false,
        hasRecentHistory: false,
        hasEventTaskTypeChooser: false,
        hasEventStateChange: false,
        hasColumnChooser: false,
        hasCreateTodoButton: false,
        hasAvailSeparatedChooser: false,
        hasMoreActions: false,
        hasRefresh: false,
        hasTimestamp: false,
        hasOfficeHoursSlider: false,
        hasClose: false,
        hasMode: false,
        hasBBStyle: false,
        onlyInitOnce: false,
        hasSnapToGrid: false,
        hasDatePropertyDropdown: false,
        datePropertyValue: null,
        hasBlackoutsCheckbox: false,
        hasAlwaysShareLocation: false,
        hasAvailLegend: false,
        hasIncludeRequested: false,
        hasEditEvent: false,
        hasRegister: false,
        canRegister: false,
        hasPagination: false,
        hasEditToggle: false,
        hasMultiQuery: false, //set by s25-const after any WS call (for embedded only, for now),
        hasCancelRequest: false,
        hasSecurity: false,
        isSearchCalendar: false,
        isCalendar: false,
        disableRelatedLocations: null,
        skipDaysPref: false,
    };

    public static EMBEDDED_OVERRIDES: any = {
        hasMoreActions: false,
        helpTopic: null,
        hasClose: false,
        hasEditEvent: false,
    };

    //DETAILS COMPONENTS
    @Timeout
    public static formEventDetailsBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "event_details",
                compsubject: "event",
                helpTopic: "view_eventsingle_detail",
                isauth: { value: true },
                hasRefresh: true,
                optCached: true,
                hasMoreActions: true,
                hasClose: true,
                hasBBStyle: false,
                hasEventStateChange: true,
                hasEditEvent: true,
                hasRegister: true,
                hasCancelRequest: true,
                hasSecurity: true,
                //event details inits self
                autoInit: false,
                hasEditToggle: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formLocationDetailsBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "location_details",
                compsubject: "location",
                helpTopic: "view_locsingle_detail",
                isauth: { value: true },
                hasRefresh: true,
                optCached: true,
                hasMoreActions: true,
                hasClose: true,
                hasBBStyle: false,
                hasAlwaysShareLocation: true,
                hasSecurity: true,
                hasEditToggle: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formResourceDetailsBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "resource_details",
                compsubject: "resource",
                helpTopic: "view_ressingle_detail",
                isauth: { value: true },
                hasRefresh: true,
                optCached: true,
                hasMoreActions: true,
                hasClose: true,
                hasBBStyle: false,
                hasEditToggle: true,
                hasSecurity: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formOrganizationDetailsBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "organization_details",
                compsubject: "organization",
                helpTopic: "view_orgsingle_detail",
                isauth: { value: true },
                hasRefresh: true,
                optCached: true,
                hasMoreActions: true,
                hasClose: true,
                hasBBStyle: false,
                hasEditToggle: true,
                hasSecurity: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formContactDetailsBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "contact_details",
                compsubject: "contact",
                helpTopic: "view_orgsingle_detail",
                isauth: { value: true },
                hasRefresh: true,
                optCached: true,
                hasMoreActions: true,
                hasClose: true,
                hasBBStyle: false,
                hasEditToggle: true,
            }),
            runTimeParams,
        );
    }

    //AVAILABILITY COMPONENTS
    @Timeout
    public static formHomeAvailabilityBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "availability_home",
                compsubject: "location",
                helpTopic: "availability_home",
                isauth: { value: true },
                date: S25Util.date.getDate(new Date()),
                chosen: { obj: {} },
                separated: { value: "" },
                editable: { value: "" },
                utilRateViz: { value: "" },
                hasRefresh: true,
                hasSearchChooser: true,
                hasMode: true,
                hasUtilViz: true,
                hasDatepicker: true,
                hasVariableDatepicker: true,
                hasTimestamp: true,
                hasAvailLegend: true,
                hasIncludeRequested: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formResourceSearchAvailabilityBean(refreshF: any, runTimeParams: any) {
        if (S25Const.embedded)
            return OptUtil.formEmbeddedAvailabilityBean(refreshF, "resource", "view_res_schedule", runTimeParams);
        return OptUtil.formSearchAvailabilityBean(refreshF, "resource", "view_res_schedule", runTimeParams);
    }

    @Timeout
    public static formLocationSearchAvailabilityBean(refreshF: any, runTimeParams: any) {
        if (S25Const.embedded)
            return OptUtil.formEmbeddedAvailabilityBean(refreshF, "location", "view_loc_schedule", runTimeParams);
        return OptUtil.formSearchAvailabilityBean(refreshF, "location", "view_loc_schedule", runTimeParams);
    }

    @Timeout
    public static formSearchAvailabilityBean(refreshF: any, compsubject: any, helpTopic: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "availability",
                compsubject: compsubject,
                helpTopic: helpTopic,
                isauth: { value: true },
                date: S25Util.date.getDate(new Date()),
                separated: { value: "" },
                editable: { value: "" },
                utilRateViz: { value: "" },
                hasRefresh: true,
                hasMode: true,
                hasDatepicker: true,
                hasVariableDatepicker: true,
                hasUtilViz: compsubject === "location",
                hasAvailLegend: true,
                hasIncludeRequested: true,
                hasListVisualization: true,
                hasCalendarVisualization: true,
                hasAvailabilityVisualization: true,
                hasAvailabilityWeeklyVisualization: compsubject === "location",
                selectedViz: "availability",
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formEmbeddedAvailabilityBean(refreshF: any, compsubject: any, helpTopic: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "availability",
                compsubject: compsubject,
                helpTopic: helpTopic,
                isauth: { value: true },
                date: S25Util.date.getDate(new Date()),
                separated: { value: "" },
                editable: { value: "" },
                utilRateViz: { value: "" },
                hasRefresh: true,
                hasMode: true,
                hasDatepicker: true,
                hasVariableDatepicker: true,
                hasUtilViz: compsubject === "location",
                hasAvailLegend: true,
                hasIncludeRequested: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formResourceDetailsAvailabilityBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formDetailsAvailabilityBean(refreshF, "resource", "view_ressingle_schedule", runTimeParams);
    }

    @Timeout
    public static formLocationDetailsAvailabilityBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formDetailsAvailabilityBean(refreshF, "location", "view_locsingle_schedule", runTimeParams);
    }

    @Timeout
    public static formDetailsAvailabilityBean(refreshF: any, compsubject: any, helpTopic: any, runTimeParams: any) {
        let date = S25Util.date.getDate(
            runTimeParams && S25Util.date.isValid(runTimeParams.date, true) ? runTimeParams.date : new Date(),
        ); //ANG-1167 load custom date
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "availability_daily",
                compsubject: compsubject,
                helpTopic: helpTopic,
                optCached: true,
                hasMoreActions: true,
                isauth: { value: true },
                date: date,
                chosen: { obj: {} },
                separated: { value: "" },
                editable: { value: "" },
                utilRateViz: { value: "" },
                hasRefresh: true,
                hasClose: true,
                hasMode: true,
                hasUtilViz: compsubject === "location",
                hasDatepicker: true,
                hasVariableDatepicker: true,
                hasBBStyle: false,
                hasAvailLegend: true,
                hasIncludeRequested: true,
                hasDaysChooser: true,
                skipDaysPref: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formDetailsWeeklyAvailabilityBean(refreshF: any, runTimeParams: any) {
        let date = S25Util.date.getDate(
            runTimeParams && S25Util.date.isValid(runTimeParams.date, true) ? runTimeParams.date : new Date(),
        ); //ANG-1167 load custom date
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "weekly",
                compsubject: "space",
                helpTopic: "view_locsingle_weekly",
                optCached: true,
                hasMoreActions: true,
                isauth: { value: true },
                date: date,
                separated: { value: "" },
                editable: { value: "" },
                utilRateViz: { value: "" },
                hasRefresh: true,
                hasClose: true,
                hasDaysChooser: true,
                hasWeeksChooser: true,
                itemTypeId: 4,
                hasDatepicker: true,
                hasWeekDatepicker: true,
                hasBBStyle: false,
                hasAvailLegend: true,
                hasIncludeRequested: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formSearchWeeklyAvailabilityBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "weekly",
                compsubject: "space",
                helpTopic: "view_locsingle_weekly",
                optCached: true,
                isauth: { value: true },
                date: S25Util.date.getDate(new Date()),
                separated: { value: "" },
                editable: { value: "" },
                utilRateViz: { value: "" },
                hasRefresh: true,
                hasOfficeHoursSlider: true,
                hasDaysChooser: true,
                hasWeeksChooser: true,
                itemTypeId: 4,
                hasDatepicker: true,
                hasWeekDatepicker: true,
                hasBBStyle: false,
                hasAvailLegend: true,
                hasIncludeRequested: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formDetailsScheduleBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "availability_schedule",
                compsubject: "event",
                helpTopic: "view_locsingle_schedule",
                optCached: true,
                hasMoreActions: true,
                isauth: { value: true },
                date: S25Util.date.getDate(new Date()),
                hasEditEvent: true,
                hasRegister: true,
                separated: { value: "" },
                editable: { value: false },
                utilRateViz: { value: "" },
                hasRefresh: true,
                hasClose: true,
                itemTypeId: 1,
                hasDatepicker: true,
                hasVariableDatepicker: true,
                hasSnapToGrid: true,
                hasBBStyle: false,
                hasMode: true,
            }),
            runTimeParams,
        );
    }

    //CALENDAR COMPONENTS
    @Timeout
    public static formHomeCalendarBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                hasStickyView: true,
                isauth: { value: true },
                date: S25Util.date.getDate(new Date()),
                chosen: { obj: {} },
                helpTopic: "subtab_home_calendar",
                comptype: "home",
                compsubject: "event",
                hasRefresh: true,
                hasSearchChooser: true,
                hasDateViews: true,
                hasDatepicker: true,
                hasVariableDatepicker: true,
                hasWeeksChooser: true,
                hasTimestamp: true,
                optCached: true,
                hasAddtlTime: true,
                hasRelatedLocationsCheckbox: true, //always show for home cal, and we manage if it is disabled based on selected search type (calendar.js code)
                isCalendar: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formSearchCalendarBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                hasStickyView: true,
                isauth: { value: true },
                date: S25Util.date.getDate(new Date()),
                chosen: { obj: {} },
                comptype: "calendar",
                hasRefresh: true,
                hasDateViews: true,
                hasDatepicker: true,
                hasVariableDatepicker: true,
                hasWeeksChooser: true,
                hasRelatedLocationsCheckbox: false, //will be marked as true for location calendar vis opt bean runtime
                isSearchCalendar: true,
                hasBlackoutsCheckbox: runTimeParams && runTimeParams.compsubject === "location",
                hasAddtlTime: true,
                isCalendar: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formEventDetailsCalendarBean(refresh: any, runTimeParams: any) {
        return OptUtil.formDetailsCalendarBean(
            refresh,
            "cal_event",
            "event",
            "view_eventsingle_calendar",
            runTimeParams,
        );
    }

    @Timeout
    public static formLocationDetailsCalendarBean(refresh: any, runTimeParams: any) {
        return OptUtil.formDetailsCalendarBean(
            refresh,
            "cal_location",
            "location",
            "view_locsingle_calendar",
            runTimeParams,
        );
    }

    @Timeout
    public static formResourceDetailsCalendarBean(refresh: any, runTimeParams: any) {
        return OptUtil.formDetailsCalendarBean(
            refresh,
            "cal_resource",
            "resource",
            "view_ressingle_calendar",
            runTimeParams,
        );
    }

    @Timeout
    public static formOrganizationDetailsCalendarBean(refresh: any, runTimeParams: any) {
        return OptUtil.formDetailsCalendarBean(
            refresh,
            "cal_organization",
            "organization",
            "view_orgsingle_calendar",
            runTimeParams,
        );
    }

    @Timeout
    public static formContactDetailsCalendarBean(refresh: any, runTimeParams: any) {
        return OptUtil.formDetailsCalendarBean(
            refresh,
            "cal_contact",
            "event",
            "view_eventsingle_calendar",
            runTimeParams,
        );
    }

    @Timeout
    public static formDetailsCalendarBean(
        refreshF: any,
        comptype: any,
        compsubject: any,
        helpTopic: any,
        runTimeParams: any,
    ) {
        let objType = comptype === "cal_contact" ? "contact" : compsubject; //contact calendar uses event data but is not an event
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                isauth: { value: true },
                date: S25Util.date.getDate(new Date()),
                chosen: { obj: {} },
                optCached: true,
                hasMoreActions: true,
                hasClose: true,
                helpTopic: helpTopic,
                comptype: comptype,
                compsubject: compsubject,
                hasRelatedEventsCheckbox: objType === "event",
                hasRefresh: true,
                hasEditEvent: objType === "event",
                hasRegister: objType === "event",
                hasDateViews: true,
                hasDatepicker: true,
                hasVariableDatepicker: true,
                hasWeeksChooser: true,
                hasBBStyle: false,
                hasRelatedLocationsCheckbox: compsubject === "location", //only show for location-based detail calendars
                disableRelatedLocations: comptype === "cal_location" ? false : undefined, //always enabled (NOT disabled) for space details calendar, else leave it up to calendar.js code
                hasBlackoutsCheckbox: compsubject === "location",
                hasAddtlTime: true,
                isCalendar: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formTaskSearchCalendarBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                isauth: { value: true },
                date: S25Util.date.getDate(new Date()),
                chosen: { obj: {} },
                helpTopic: "tasks_agenda",
                comptype: "cal_task_search",
                compsubject: "event",
                itemTypeId: 10,
                hasRefresh: true,
                hasDateViews: true,
                hasDatepicker: true,
                hasVariableDatepicker: true,
                hasWeeksChooser: true,
                hasDatePropertyDropdown: true,
                datePropertyValue: "respond_by",
                hasCreateTodoButton: true,
                isCalendar: true,
            }),
            runTimeParams,
        );
    }

    //LIST COMPONENTS
    @Timeout
    public static formEventListBean(refreshF?: any, runTimeParams?: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "list",
                compsubject: "event",
                date: S25Util.date.getDate(new Date()),
                defaultDatesOption: { value: "futureOnly" },
                datesOption: {},
                helpTopic: "view_myevents_list",
                hasStickyView: true,
                hasDateViews: true,
                hasFutureOnly: true,
                hasAllDates: true,
                hasRecentHistory: true,
                hasColumnChooser: true,
                hasDatepicker: true,
                hasVariableDatepicker: true,
                hasDaysSelector: true,
                hasRefresh: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formNonEventItemListBean(refreshF: any, compsubject: any, helpTopic: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "list",
                compsubject: compsubject,
                helpTopic: helpTopic,
                hasColumnChooser: true,
                hasRefresh: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formLocationListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formNonEventItemListBean(refreshF, "location", "view_loc_list", runTimeParams);
    }

    @Timeout
    public static formResourceListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formNonEventItemListBean(refreshF, "resource", "view_res_list", runTimeParams);
    }

    @Timeout
    public static formOrganizationListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formNonEventItemListBean(refreshF, "organization", "view_org_list", runTimeParams);
    }

    @Timeout
    public static formContactListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formNonEventItemListBean(refreshF, "contact", "view_cont_list", runTimeParams);
    }

    @Timeout
    public static formPublisherListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formNonEventItemListBean(refreshF, "publisher", "publisher_admin", runTimeParams);
    }

    @Timeout
    public static formBlackoutListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formNonEventItemListBean(refreshF, "blackout", false, runTimeParams);
    }

    @Timeout
    public static formPricingListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formNonEventItemListBean(refreshF, "pricing", "pricing_admin", runTimeParams);
    }

    @Timeout
    public static formImageListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formNonEventItemListBean(refreshF, "image", "image_admin", runTimeParams);
    }

    @Timeout
    public static formQuotaListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formNonEventItemListBean(refreshF, "quota", "quota_admin", runTimeParams);
    }

    @Timeout
    public static formRsrvDetailsListBean(refreshF: any, compsubject: any, helpTopic: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "list",
                compsubject: compsubject,
                date: S25Util.date.getDate(new Date()),
                helpTopic: helpTopic,
                optCached: true,
                hasDatepicker: true,
                hasColumnChooser: true,
                hasRefresh: true,
                hasMoreActions: true,
                hasClose: true,
                hasVariableDatepicker: true,
                hasDaysSelector: true,
                hasBBStyle: false,
                hasRelatedLocationsCheckbox: compsubject === "rm_rsrv",
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formLocationDetailsListBean(refresh: any, runTimeParams: any) {
        return OptUtil.formRsrvDetailsListBean(refresh, "rm_rsrv", "view_locsingle_list", runTimeParams);
    }

    @Timeout
    public static formResourceDetailsListBean(refresh: any, runTimeParams: any) {
        return OptUtil.formRsrvDetailsListBean(refresh, "rs_rsrv", "view_ressingle_list", runTimeParams);
    }

    @Timeout
    public static formOrganizationDetailsListBean(refresh: any, runTimeParams: any) {
        return OptUtil.formRsrvDetailsListBean(refresh, "org_rsrv", "view_orgsingle_list", runTimeParams);
    }

    @Timeout
    public static formContactDetailsListBean(refresh: any, runTimeParams: any) {
        return OptUtil.formRsrvDetailsListBean(refresh, "cont_rsrv", "view_contsingle_list", runTimeParams);
    }

    @Timeout
    public static formHomeListBean(refresh: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refresh,
                comptype: "homelist",
                compsubject: "event",
                date: S25Util.date.getDate(new Date()),
                helpTopic: "view_myevents_list",
                optCached: true,
                hasDatepicker: true,
                hasRefresh: true,
                hasVariableDatepicker: true,
                hasDaysSelector: true,
                chosen: { obj: {} },
                hasSearchChooser: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formAuditListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "list",
                compsubject: "event_audit",
                helpTopic: "view_eventsingle_audit",
                optCached: true,
                hasRefresh: true,
                hasMoreActions: true,
                hasClose: true,
                hasBBStyle: false,
                hasEditEvent: runTimeParams && runTimeParams.itemTypeId === 1,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formEventTaskListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "list",
                compsubject: "task",
                helpTopic: "view_eventsingle_tasklist",
                optCached: true,
                hasRefresh: true,
                hasMoreActions: true,
                hasClose: true,
                hasEventTaskTypeChooser: true,
                taskType: "1",
                hasRelatedEventsCheckbox: true,
                hasBBStyle: false,
                hasEventStateChange: true,
                hasEditEvent: true,
                autoInit: false, //false bc now it is a check list and therefore inits self
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formTaskOverviewListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                comptype: "list",
                compsubject: "task",
                helpTopic: "tasks_browse",
                hasRefresh: true,
                hasCreateTodoButton: true,
                hasStickyView: true,
                hasColumnChooser: true,
            }),
            runTimeParams,
        );
    }

    //Event Details List
    @Timeout
    public static formEventDetailsListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formOptBean(
            S25Util.merge(S25Util.deepCopy(OptUtil.DEFAULTS), {
                refreshF: refreshF,
                hasRefresh: true,
                comptype: "event-details-list",
                hasPagination: true,
            }),
            runTimeParams,
        );
    }

    @Timeout
    public static formSignedInUsersListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formNonEventItemListBean(refreshF, "signedInUsers", "signed-in-users-list", runTimeParams);
    }

    @Timeout
    public static formEventTypeListBean(refreshF: any, runTimeParams: any) {
        return OptUtil.formNonEventItemListBean(refreshF, "evenTypeList", "event-type-list", runTimeParams);
    }

    //BEAN GENERATOR
    @Timeout
    public static formOptBean(params: any, runTimeParams: any) {
        let ret: any = {};

        S25Util.extend(ret, params, runTimeParams, S25Util.isInIframe ? OptUtil.EMBEDDED_OVERRIDES : {});
        ret.origCompsubject = ret.compsubject; //set orig compsubject bc calendar changes compsubject based on some params (eg include related locations)

        if (ret.autoInit) {
            let getS25Opt = ret.optCached ? OptService.getS25OptCached : OptService.getS25Opt;
            return getS25Opt(ret.comptype, ret.compsubject, ret.searchContext).then(
                function (data) {
                    //get options for this comp type (searchContext used for cache key)
                    if (data.modelBean && ret.optCached) {
                        data.modelBean.date = ret.date || data.modelBean.date || new Date(); //check ret.date bc date may need updating via new stateParams in a state transition
                        data.modelBean.refreshF = ret.refreshF; //reset refreshF so we do not refresh a dead scope (refreshF might enclose a scope and call an API with it...)
                        return data.modelBean;
                    } else {
                        let pref = data && data.pref;
                        let config = pref && pref.config;
                        if (config) {
                            config.isSeparated = parseInt(config.isSeparated);
                            if (config.isSeparated >= 0) {
                                ret.separated = { value: config.isSeparated };
                            }
                        }

                        if (ret.datesOption) {
                            //set default datesOption if not passed in
                            ret.datesOption.value =
                                (ret.datesOption && ret.datesOption.value) ||
                                (pref && pref.dates_option) ||
                                (ret.defaultDatesOption && ret.defaultDatesOption.value);
                        }

                        ret.options = S25Util.coalesce(pref && pref.opt, null);
                        if (ret.chosen && ret.options) {
                            ret.chosen.obj = S25Util.coalesce(ret.options[pref.default_cal], ret.options[0]);
                        }

                        ret.date = ret.date || new Date();
                        ret.hasEditableCheckbox =
                            S25Util.toBool(
                                S25Util.coalesce(ret.hasEditableCheckbox, config && config.avail_show_edit),
                            ) && !S25Util.isInIframe;
                        if (ret.hasEditableCheckbox) {
                            ret.hasSnapToGrid = true; //show snap-to-grid if user can edit since this is an editing config
                        }
                        ret.hasUtilViz = ret.hasUtilViz && config && config.avail_show_utilviz;
                        if (ret.hasUtilViz && config && config.utilviz) {
                            ret.utilRateViz = { value: config.utilviz[0] };
                        }
                        ret.utilVizChoices = config && config.utilviz;
                        if (ret.optCached) {
                            data.modelBean = ret;
                        }

                        return ret;
                    }
                },
                function (error: any) {
                    ret.messages.message = S25Util.propertyGet(error && error.data, "r25error") || ""; //display r25error from DB
                    if (S25Util.isDefined(ret.isauth)) {
                        ret.isauth.value = false; //set auth to false
                    }
                    return Promise.reject(ret); // throw to calling function (ie, a controller, which can set some bean to show the error message). TS needs to return  Promise.reject to pass Unit test
                },
            );
        } else {
            return jSith.when(ret);
        }
    }

    public static getOptBean(
        view: AvailCompType,
        query: string,
        itemId?: number,
        itemType?: Item.Id,
    ): Promise<OptBean> {
        const generator = OptUtil.getOptBeanGenerator(view, itemType);
        return generator(() => {}, { itemId, itemTypeId: itemType, searchContext: query });
    }

    public static getOptBeanGenerator(view: AvailCompType, itemType?: Item.Id) {
        if (view === "availability_home") return OptUtil.formHomeAvailabilityBean;
        if (view === "availability_schedule") return OptUtil.formDetailsScheduleBean;
        if (view === "availability") {
            if (itemType === Item.Ids.Location) return OptUtil.formLocationSearchAvailabilityBean;
            if (itemType === Item.Ids.Resource) return OptUtil.formResourceSearchAvailabilityBean;
        }
        if (view === "availability_daily") {
            if (itemType === Item.Ids.Location) return OptUtil.formLocationDetailsAvailabilityBean;
            if (itemType === Item.Ids.Resource) return OptUtil.formResourceDetailsAvailabilityBean;
        }
    }
}
